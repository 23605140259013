var Pikaday = require('pikaday');
window.Pikaday = Pikaday;

var startDate,
    startRangeDate,
    startRangeDateMultichange,
    startRangeDateUntil,
    endRangeDate,
    endRangeDateMultichange,
    endRangeDateUntil,
    maxDate = new Date(2029, 12, 31),
    minDate = new Date(),
    disable = false,
    updateStartDate = function () {
        //startRangePicker.setStartRange(startRangeDate);
        startRangePicker.setMaxDate(maxDate);
        endRangePicker.setStartRange(startRangeDate);
        endRangePicker.setMinDate(startRangeDate);

        if (startRangeDate.getTime() >= endRangeDate.getTime()) {
            endRangePicker.setDate(startRangeDate);
        }

    },
    updateEndDate = function () {
        startRangePicker.setEndRange(endRangeDate);
        startRangePicker.setMaxDate(maxDate);
        endRangePicker.setEndRange(endRangeDate);
    },

    updateStartDateMultichange = function () {
        startRangePickerMultichange.setMaxDate(maxDate);
        endRangePickerMultichange.setStartRange(startRangeDateMultichange);
        endRangePickerMultichange.setMinDate(startRangeDateMultichange);

        if (startRangeDateMultichange.getTime() >= endRangeDateMultichange.getTime()) {
            endRangePickerMultichange.setDate(startRangeDateMultichange);
        }

    },
    updateEndDateMultichange = function () {
        startRangePickerMultichange.setEndRange(endRangeDateMultichange);
        startRangePickerMultichange.setMaxDate(maxDate);
        endRangePickerMultichange.setEndRange(endRangeDateMultichange);
    },

    updateStartDateUntil = function () {
        //debugger;
        //startRangePicker.setStartRange(startRangeDate);
        startRangePickerUntil.setMaxDate(maxDate);
        endRangePickerUntil.setStartRange(startRangeDateUntil);
        endRangePickerUntil.setMinDate(startRangeDateUntil);

        if (startRangeDateUntil.getTime() > endRangeDateUntil.getTime()) {
            endRangePickerUntil.setDate(startRangeDateUntil);
        }

    },
    updateEndDateUntil = function () {
        startRangePickerUntil.setEndRange(endRangeDate);
        startRangePickerUntil.setMaxDate(maxDate);
        endRangePickerUntil.setEndRange(endRangeDate);
    },
    convertArrayToDateString = function(elem, index,array) {
        var myDate = new Date(elem);
        //console.log (mydate.toDateString());
        array[index] = myDate.toDateString();
        return array[index];
    },
    convertDateArray = function(array) {
        array.forEach(convertArrayToDateString);
        return array;
    },
    blockedDays = window.blockedDays ? window.blockedDays : [6,0];
    absentDates = ['2018-12-22', '2018-12-23', '2018-12-24', '2018-12-30','2018-12-31'],
    events = window.absentDays ? convertDateArray(window.absentDays) : [],
    format = 'DD.MM.YYYY',
    i18n = {
        previousMonth: 'Letzter Monat',
        nextMonth: 'Nächster Monat',
        months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
        weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
    },
    minDate = (window.allowPastDates == '1') ? null : new Date(),
    startPicker = new Pikaday({
        field: document.querySelector('.single-datepicker-start'),
        //  trigger: $("#datepicker-start").parent().find('.calendar-btn')[0],
        minDate: minDate,
        maxDate: maxDate,
        //container: document.getElementById('form-meal-calendar'),
        i18n: i18n,
        showWeekNumber: true,
        firstDay: 1,
        events: events,
        format: format,
        //disableWeekends: true,
        disableDayFn: function (date) {
            // Disable Saturday, Sunday
            // return (date.getDay() === 6 || date.getDay() === 0);
            return ($.inArray(date.getDay(), blockedDays)==-1?false:true);
        },
        // callback function for when the picker becomes visible
        onOpen: function (date) {
            //console.dir("onOpen");
            //console.dir(this);
        },
        // callback function for when the picker draws a new month
        onDraw: function (date) {
            //console.dir(date);
            //console.dir(date.calendars[0].month);
        },
        onSelect: function () {
            //startRangeDate = this.getDate();
            //updateStartDate();
        }
    }),
    startRangePicker = new Pikaday({
        field: document.querySelector('.range-datepicker-start'),
        // trigger: $("#datepicker-start").parent().find('.calendar-btn')[0],

//        minDate: new Date(),
        maxDate: maxDate,
        //container: document.getElementById('form-meal-calendar'),
        i18n: i18n,
        format: format,
        showWeekNumber: true,
        firstDay: 1,
        events: events,
//        disableWeekends: true,
        disableDayFn: function (date) {
            // Disable Saturday, Sunday
            // return (date.getDay() === 6 || date.getDay() === 0);
            return ($.inArray(date.getDay(), blockedDays)==-1?false:true);
        },
        onSelect: function () {
            startRangeDate = this.getDate();
            endRangeDate = endRangePicker.getDate();
            updateStartDate();
        }
    }),
    endRangePicker = new Pikaday({
        field: document.querySelector('.range-datepicker-end'),
        // trigger: $("#datepicker-end").parent().find('.calendar-btn')[0],

        minDate: new Date(),
        maxDate: maxDate,
        //container: document.getElementById('form-meal-calendar'),
        i18n: i18n,
        format: format,
        showWeekNumber: true,
        firstDay: 1,
        events: events,
//        disableWeekends: true,
        disableDayFn: function (date) {
            // Disable Saturday, Sunday
            // return (date.getDay() === 6 || date.getDay() === 0);
            return ($.inArray(date.getDay(), blockedDays)==-1?false:true);
        },
        onSelect: function () {

            endRangeDate = this.getDate();
            updateEndDate();
        }
    }),

    startPickerMultichange = new Pikaday({
        field: document.querySelector('.single-multichange-datepicker-start'),
        minDate: new Date(),
        maxDate: maxDate,
        i18n: i18n,
        showWeekNumber: true,
        firstDay: 1,
        events: events,
        format: format,
        disableDayFn: function (date) {
            return ($.inArray(date.getDay(), blockedDays)==-1?false:true);
        },
        // callback function for when the picker becomes visible
    }),
    startRangePickerMultichange = new Pikaday({
        field: document.querySelector('.range-multichange-datepicker-start'),
        minDate: new Date(),
        maxDate: maxDate,
        i18n: i18n,
        format: format,
        showWeekNumber: true,
        firstDay: 1,
        events: events,
        disableDayFn: function (date) {
            return ($.inArray(date.getDay(), blockedDays)==-1?false:true);
        },
        onSelect: function () {
            startRangeDateMultichange = this.getDate();
            endRangeDateMultichange = endRangePickerMultichange.getDate();
            updateStartDateMultichange();
        }
    }),
    endRangePickerMultichange = new Pikaday({
        field: document.querySelector('.range-multichange-datepicker-end'),
        minDate: new Date(),
        maxDate: maxDate,
        i18n: i18n,
        format: format,
        showWeekNumber: true,
        firstDay: 1,
        events: events,
        disableDayFn: function (date) {
            return ($.inArray(date.getDay(), blockedDays)==-1?false:true);
        },
        onSelect: function () {
            endRangeDateMultichange = this.getDate();
            updateEndDateMultichange();
        }
    }),

    startRangePickerUntil = new Pikaday({
        field: document.querySelector('.range-datepicker-start-until'),
        // trigger: $("#datepicker-start").parent().find('.calendar-btn')[0],

        //minDate: new Date(),
        maxDate: maxDate,
        //container: document.getElementById('form-meal-calendar'),
        i18n: i18n,
        format: format,
        showWeekNumber: true,
        firstDay: 1,
        events: events,
//        disableWeekends: true,
        disableDayFn: function (date) {
            // Disable Saturday, Sunday
            // return (date.getDay() === 6 || date.getDay() === 0);
            return ($.inArray(date.getDay(), blockedDays)==-1?false:true);
        },
        onSelect: function () {
            startRangeDateUntil = this.getDate();
            endRangeDateUntil = endRangePickerUntil.getDate();
            updateStartDateUntil();
        }
    }),
    endRangePickerUntil = new Pikaday({
        field: document.querySelector('.range-datepicker-end-until'),
        // trigger: $("#datepicker-end").parent().find('.calendar-btn')[0],

        //minDate: new Date(),
        maxDate: new Date(2029, 12, 31),
        //container: document.getElementById('form-meal-calendar'),
        i18n: i18n,
        format: format,
        showWeekNumber: true,
        firstDay: 1,
        events: events,
       // disableWeekends: true,
        disableDayFn: function (date) {
            // Disable Saturday, Sunday
            // return (date.getDay() === 6 || date.getDay() === 0);
            return ($.inArray(date.getDay(), blockedDays)==-1?false:true);
        },
        onSelect: function () {
            endRangeDateUntil = this.getDate();
            updateEndDateUntil();
        }
    }),
    _startDate = startPicker.getDate(),
    _startRangeDate = startRangePicker.getDate(),
    _endRangeDate = endRangePicker.getDate();

/*
if (_startRangeDate) {
    startRangeDate = _startRangeDate;
    updateStartDate();
}
if (_endRangeDate) {
    endRangeDate = _endRangeDate;
    updateEndDate();
}
*/

if ($('html').hasClass('no-touch')) {
    $('.date-wrapper input').attr('type', 'text');
} else {
    $('.date-wrapper input').attr('type', 'date');
    //$('.calendar-btn').hide();
}


if ($("#newpwd").length) {
    var $password = $("#newpwd"),
        $confirmPassword = $("#confirm_newpwd");

    $password.bootstrapPasswordMeter({minPasswordLength: 6});

    function arePasswordsMatching() {
        return $password.val() === $confirmPassword.val();
    }

    function isPasswordValid() {
        return $password.val().length >= 6;
    }

    function canSubmit() {
        return isPasswordValid() && arePasswordsMatching();
    }

    function passwordEvent() {
        //Find out if password is valid
        if (isPasswordValid()) {
            //Hide hint if valid
        } else {
            //else show hint
            //$confirmPassword.setCustomValidity("Passwort ist zu kurz");
        }
    }

    function confirmPasswordEvent() {
        //Find out if password and confirmation match
        if (arePasswordsMatching()) {
            //Hide hint if match
        } else {
            //else show hint
            //$confirmPassword.setCustomValidity("Passwörter stimmen nicht überein");
        }
    }

    function enableSubmitEvent() {
        $("#submit").prop("disabled", !canSubmit());
    }


    //When event happens on password input
    $password.focus(passwordEvent).keyup(passwordEvent).keyup(confirmPasswordEvent).keyup(enableSubmitEvent);

//When event happens on confirmation input
    $confirmPassword.focus(confirmPasswordEvent).keyup(confirmPasswordEvent).keyup(enableSubmitEvent);

    enableSubmitEvent();
}

//$.datepicker.setDefaults($.datepicker.regional['de']);
/*
$(".userAccountEditDate, .editDatePicker, .dateInput").datepicker({
    showOn:"both",
    buttonImage: mealoBaseUrl+"Apps/Mealo/Views/frontend/_resources/images/icons/ico_calendar.png",
    buttonImageOnly:true,
    dateFormat: 'dd.mm.yy',
    beforeShowDay: function(date){
        var numericDay = date.getDay();
        if( numericDay == 6 || numericDay == 0 ) {
            return [ false, 'calendarRedBG', 'Weekend' ];
        }
        else if( MealoAllHolidays && MealoAllHolidays.length > 0 ) {
            var fullYear = date.getFullYear();
            var month    = date.getMonth() + 1;
            var dd       = date.getDate();
            if( dd < 10 ) {
                dd = '0' + dd;
            }
            if( month < 10 ) {
                month = '0' + month;
            }

            var currentDate = fullYear + '-' + month + '-' + dd;

            if( MealoAllHolidays.indexOf( currentDate ) >= 0 ) {
                return [ false, 'calendarRedBG', 'Holiday' ];
            } else {
                return [ true ];
            }
        }
        else {
            return [ true ];
        }
    }
});
*/
$(".userAccountEditDate").on('change', function () {
    console.log("#userAccountEditDate");
    var form = $(this).parent().parent();
    //form.find('.control').val('change');
    //form.submit();
});


var loadUI = function () {
    //console.log("data-tables.js loaded");
}();

function getColumnIdByHeaderId(headerId) {

    var header = $('#userTable thead td');
    for (var i = 0; i < header.length; i++) {
        if (header[i].id == headerId) return i;
    }
    return -1;
}

var logUrl = "/backend/Log/createLog";
var userName = "admin"

function toggleValue(id) {
    if ($('#' + id).val() == "1") {
        $('#' + id).val("0");
        $('#' + id + '_button').removeClass("active");
    } else {
        $('#' + id).val("1");
        $('#' + id + '_button').addClass("active");
    }

    loadTable();
}


function getActivePriceGroups() {
    var ret = new Array();
    var groups = $(".pricegroups");
    for (var i = 0; i < groups.length; i++) {
        if (groups[i].value == 1) {
            ret.push(groups[i].id.replace("pg_", ""));
        }
    }
    return ret.join(';');
}

function getActiveGroups() {
    var ret = new Array();
    var groups = $(".groups");
    for (var i = 0; i < groups.length; i++) {
        if (groups[i].value == 1) {
            ret.push(groups[i].id.replace("g_", ""));
        }
    }
    return ret.join(';');
}


var columns = [
    // absence
    {"className": "uk-width-auto uk-table-shrink"},
    // number
    {"visible": false},
    //name
    {"className": "uk-width-auto uk-table-shrink"},
    // class
    {"className": "uk-text-nowrap uk-width-auto uk-table-shrink"},
    // group
    {"className": "uk-text-nowrap uk-width-auto uk-table-shrink"},
    // meal
    {"className": "uk-text-nowrap uk-width-auto uk-table-shrink mealo-meal"},
    // extmeal_1
    {"className": "uk-text-nowrap uk-width-auto uk-table-shrink mealo-extmeal1"},
    // extmeal_2
    {"className": "uk-text-nowrap uk-width-auto uk-table-shrink mealo-extmeal2"},
    // extmeal_3
    {"className": "uk-text-nowrap uk-width-auto uk-table-shrink mealo-extmeal3"},
    // extmeal_4
    {"className": "uk-text-nowrap uk-width-auto uk-table-shrink mealo-extmeal4"},
    // comment
    null,
    // action
    {"searchable": false, "className": "uk-table-shrink"},
];

function loadTable(search) {
    var searchUrl = window.ajaxGetUsers ? window.ajaxGetUsers + "?groups=" + getActiveGroups() + "&pricegroups=" + getActivePriceGroups() + "&date=" + $('.range-datepicker-start').val() : "./adata.json";

    var table = $('.group-overview').DataTable({
        "language": {
            "url": "/web/i18n/dataTables.german.lang"
        },
        "destroy": true,
        "select": true,
        "autoWidth": false,
        "columns": (window.columns) ? window.columns : columns,
        "lengthMenu": [ [5, 10, 25, 50, -1], [5, 10, 25, 50, "Alle"] ],
        "ajax": {
            "url": searchUrl,
            "dataSrc": "data"
        },
        "rowCallback": function (row, data, displayNumber, displayIndex) {

            var $mealHeaderTds = $('#userTable thead tr').find('td[data-submitUrl]');
            $mealHeaderTds.each(function() {
                var oldValue = "";
                var columnIndex = $(this).index();
                var tapped = false;

                function switchSelect(e) {
                    var $clickedTD = $(this);
                    var submitUrl = $('#userTable tr').eq(0).find('td:eq(' + columnIndex + ')').data('submiturl');
                    if(!$clickedTD.hasClass('editable')) {
                        return;
                    }

                    $clickedTD.removeClass('editable');
                    $clickedTD.addClass('editing');

                    var moreThanOneSpan = ($clickedTD.find('.edit').length > 1) ? true : false;

                    $clickedTD.find('.edit').each(function() {

                        var $currentSpan = $(this);

                        oldValue = $currentSpan.text();
                        // console.log(oldValue);
                        var selectId = $currentSpan.data('select');
                        var uid = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
                        var $Select = $('#' + selectId + ' select').clone();
                        $Select.attr('id', uid);
                        $Select.data('oldval', oldValue);
                        $Select.addClass("uk-select uk-width-auto uk-form-small")

                        // console.log('edit');
                        var data = Object.create(null);     //creating an empty object
                        data['userId'] = $(this).parents('tr').attr('id');
                        data['orderDate'] = $('#range-datepicker-start').val();
                        data['selectId'] = selectId;

                        $.ajax({
                            url: allowedMealsUrl,
                            data: data
                        }).done(function(data) {
                            if(data.AllowedMealIds !== undefined && Array.isArray(data.AllowedMealIds) && data.AllowedMealIds.length>0) {
                                $("#"+uid+" option").each(function() {
                                    $(this).hide();
                                    var mealId = $(this).val().substring($(this).val().indexOf('_')+1, $(this).val().length);
                                    if(jQuery.inArray(parseInt(mealId), data.AllowedMealIds) != -1) {
                                        $(this).show();
                                    }
                                });
                            }
                        });

                        if(!moreThanOneSpan) {
                            $Select.bind('change blur', function() {
                                var data = Object.create(null);     //creating an empty object
                                data['userId'] = $(this).parents('tr').attr('id');
                                data['orderDate'] = $('#range-datepicker-start').val();
                                data['orderDateEnd'] = $('#range-datepicker-end').val();
                                var name = $(this).attr('name');
                                var value = $(this).val();
                                data[name] = value;

                                $.ajax({
                                    url: submitUrl,
                                    data: data
                                }).done(function(data) {
                                    $clickedTD.find('select').each(function() {
                                        var newValue = $(this).find('option:selected').text();
                                        $(this).parent().html(newValue);
                                    });
                                    $clickedTD.addClass('editable');
                                    $clickedTD.removeClass('editing');
                                    UIkit.notification({
                                        message: 'Änderungen gespeichert!',
                                        status: 'primary',
                                        pos: 'bottom-center',
                                        timeout: 1500
                                    });

                                });
                            });
                        }

                        $currentSpan.html($Select);

                        if(!moreThanOneSpan) {
                            $Select.focus();
                        }

                        var id = $("#" + uid + " option:contains(\"" + oldValue + "\")").val();
                        $('#' + uid).val(id);
                    });

                    var $saveButton = $('<button class="uk-icon uk-icon-button uk-icon-primary save" uk-icon="icon: check" type="button"></button>');
                    $saveButton.click(function() {
                        var $button = $(this);
                        var data = Object.create(null);     //creating an empty object
                        data['userId'] = $(this).parents('tr').attr('id');
                        data['orderDate'] = $('#range-datepicker-start').val();
                        data['orderDateEnd'] = $('#range-datepicker-end').val();

                        // filling the data param with the selects present
                        $(this).parents('td').find('select').each(function() {
                            var name = $(this).attr('name');
                            var value = $(this).val();
                            data[name] = value;
                        });

                        $.ajax({
                            url: submitUrl,
                            data: data
                        }).done(function(data) {
                            $clickedTD.find('select').each(function() {
                                var newValue = $(this).find('option:selected').text();
                                $(this).parent().html(newValue);
                            });
                            $button.parent().find('button').remove();
                            $clickedTD.addClass('editable');
                            $clickedTD.removeClass('editing');
                            UIkit.notification({
                                message: 'Änderungen gespeichert!',
                                status: 'primary',
                                pos: 'bottom-center',
                                timeout: 1500
                            });
                        });
                    });

                    var $cancelButton = $('<button class="uk-icon uk-icon-button cancel"  uk-icon="icon: close" type="button"></button>');
                    $cancelButton.click(function() {
                        //console.log("hey");
                        var $button = $(this);
                        $clickedTD.find('select').each(function() {
                            var oldValue = $(this).data('oldval');
                            $(this).parent().html(oldValue);
                        });
                        $button.parent().find('button').remove();
                        $clickedTD.addClass('editable');
                        $clickedTD.removeClass('editing');
                    });

                    if(moreThanOneSpan) {
                        $clickedTD.append($cancelButton);
                        $clickedTD.append($saveButton);
                    }


                }

                $('td:eq(' + columnIndex + ')', row).addClass("editable");
                $('td:eq(' + columnIndex + ')', row).on("click touchstart", switchSelect);
            });

            function switchSelectComment() {
                var oldValue = $(this).text().replace(/ /g, '');
                var uid = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
                $(this).html($('#commentInput').html());
                $(this).children().attr('id', uid);
                $('#' + uid).focus();
                $('#' + uid).val(oldValue);
                $('#' + uid).addClass("uk-input uk-form-small");
                $('#' + uid).bind('change blur', function() {
                    var userId = $(this).parent().parent().attr('id');
                    $.ajax({
                        'url': window.saveCommentUrl + '?comment=' + $('#' + $(this).attr('id')).val() + '&userId=' + userId + '&orderDate=' + $('#range-datepicker-start').val() + '&selectid=' + uid,
                    }).done(function(data) {
                        $('#' + data).parent().html($('#' + data).val());
                        UIkit.notification({
                            message: 'Kommentar gespeichert!',
                            status: 'primary',
                            pos: 'bottom-center',
                            timeout: 1500
                        });
                    });
                });
                $('.directedit').keyup(function(event) {
                    if(event.which == 13) {
                        $(this).trigger("change");
                    }
                    if(event.which == 27) {
                        $(this).parent().html(oldValue);
                    }
                });
            }

            if($('td:eq(' + getColumnIdByHeaderId("comment") + ')', row).hasClass('editable')) {
                $('td:eq(' + getColumnIdByHeaderId("comment") + ')', row).addClass("editable");
                $('td:eq(' + getColumnIdByHeaderId("comment") + ')', row).on("click touchstart", switchSelectComment);
            }
        },

        initComplete: function () {
            $(".select-filter-group").empty();
            this.api().columns(".filter-group").every( function () {
                var column = this;
                var columnHeader = column.header(),
                    columnHeaderText = columnHeader.innerText;
                var select = $('<select class="uk-select uk-width-auto uk-form-small uk-margin-right"><option value="">Alle '+ columnHeaderText +'n</option></select>')
                    .appendTo( $('.select-filter-group') )
                    .on( 'change', function () {
                        var val = $.fn.dataTable.util.escapeRegex(
                            $(this).val()
                        );

                        column
                            .search( val ? '^'+val+'$' : '', true, false )
                            .draw();
                    } );

                column.data().unique().sort().each( function ( d, j ) {
                    select.append( '<option value="'+d+'">'+d+'</option>' )
                } );
            } );
        }

    });

    if(search != null) {
        //console.log("search var found");
        table.search(search);
    }
}


$('.range-datepicker-start').change(function () {
    loadTable();
});

function openModifyWindow(userIds) {
    if(!userIds.length) {
        return;
    }
    $('#multiEditUserFormSingle #userIds').val(userIds.join(','));
    $('#multiEditUserFormRange #userIds').val(userIds.join(','));
    UIkit.modal('#modifyDialog').show();
}

function saveMultiEditUser(form) {
    $.ajax({
        url     : form.attr('action'),
        type    : form.attr('method'),
        data    : form.serialize(),
    }).done(function(response){
        if(response.success) {
            var table = $('.group-overview').DataTable();
            table.rows().deselect();
            table.ajax.reload();
            UIkit.modal('#modifyDialog').hide();
            UIkit.notification({
                message: 'Mehrfachänderung erfolgreich!',
                status: 'primary',
                pos: 'bottom-center',
                timeout: 2000
            });
        } else {
            UIkit.notification({
                message: 'Fehler Mehrfachänderung: '+response.errorMsg,
                status: 'primary',
                pos: 'bottom-center',
                timeout: 5000
            });
        }
    }).fail(function (jqXHR, textStatus, errorThrown) {
        UIkit.notification({
            message: 'Fehler Mehrfachänderung: '+errorThrown,
            status: 'primary',
            pos: 'bottom-center',
            timeout: 5000
        });
    });
}

$(document).ready(function() {
    if (window.searchQuery != '') {
        var table = $('.group-overview').DataTable();
        table.search(window.searchQuery);
    }

    var datatableSearch = $('#userTable_filter').find("input[type='search']");
    datatableSearch.on('keyup',function(e) {
        if(datatableSearch.val() == "") {
            window.history.replaceState(null, null, window.location.pathname);
        }
    });

    $('#selectAll').click(function(e){
        var table = $('.group-overview').DataTable();
        table.rows({ search: 'applied' }).select();
    });

    $('#deselectAll').click(function(e){
        var table = $('.group-overview').DataTable();
        table.rows().deselect();
    });

    $('#editSelected').click(function(e){
        var table = $('.group-overview').DataTable();
        var selected = table.rows({
            selected: true
        });
        var selectedRows = selected[0];
        var userIds = new Array();
        for (var i = 0; i < selectedRows.length; i++) {
            var row = table.rows(selectedRows[i]).data().toArray();
            userIds.push(row[0]['DT_RowId']);
        }
//        alert(userIds.join(','));

        openModifyWindow(userIds);
    });

    $('#multiEditUserFormSingle').submit(function(e) {
        saveMultiEditUser($(this));
        e.preventDefault();
    });

    $('#multiEditUserFormRange').submit(function(e) {
        saveMultiEditUser($(this));
        e.preventDefault();
    });

    $('#saveModifyWindow').click(function(e){
        if($('.multiEditUserSingle').hasClass('uk-active')) {
            $('#multiEditUserFormSingle').submit();
        }
        if($('.multiEditUserRange').hasClass('uk-active')) {
            $('#multiEditUserFormRange').submit();
        }
    });

    $('.group-overview').on( 'search.dt', function () {
        var table = $('.group-overview').DataTable();
        table.rows().deselect();
    });

    $('.group-overview').on('click','tr',function(e){
        e.stopPropagation();
        var rowID = $(this).attr('id');
        var table = $('.group-overview').DataTable();
        if($(this).hasClass('selected')) {
            table.rows('#'+rowID).deselect();
        } else {
            table.rows('#'+rowID).select();
        }
    })
});

/*
var hideTableColumns = $('.hide-table-columns').detach();
hideTableColumns.appendTo('.filter-columns').removeClass("uk-hidden");
*/

$(".check-all").on('change', function (e) {
    var state = $(".check-all").prop("checked"),
        selectAll = document.querySelectorAll('tbody .uk-checkbox');

    for (var i = 0; i <= selectAll.length; i++) {
        selectAll[i].checked = state;
    }

});
$('.toggle-vis').on('change', function (e) {
    $(".group-overview").width("100%");
    // Get the column API object
    var table = $('.group-overview').DataTable();
    var column = table.column($(this).attr('data-column'));
    // Toggle the visibility
    column.visible(!column.visible());
});
